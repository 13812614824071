
.pList{
    width: 100%;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.pListItem{
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

}

.pListImg{
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.pListTitles>h1{
    font-size: 18px;
}

.pListTitles>h2{
    font-size: 14px;
    font-weight: 300px;
}