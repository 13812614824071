


.homeContainer{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .homeTitle{
    width: 1024px;
    font-size: 20px;
  }